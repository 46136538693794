import React from 'react';
import styled from '@emotion/styled';

const BlankSpaceContainer = styled.div`
  height: ${props => props.height};
  width: 100%;
  background-color: ${props => props.color};
  @media (max-width: 735px) {
    height: ${props => props.mobileHeight};
  }
`;

const BlankSpace = props => (
  <BlankSpaceContainer
    height={props.height}
    mobileHeight={props.mobileHeight}
    color={
      props.space_background_color === null
        ? '#ffffff'
        : props.space_background_color
    }
  ></BlankSpaceContainer>
);

export default BlankSpace;
